<template>

  <div class="widget">

    <div class="widget-header">
      <div class="widget-header-label">
        <h3>{{ title }}</h3>

      </div>
      <div class="widget-header-toolbar">
        <div class="actions">
          <!-- <div class="btn btn-grey btn-bold ml-3"><i class="fas fa-download"></i></div> -->
        </div>
      </div>
    </div>
    <div class="widget-body no-padding">

      <table class="vuetable">
        <thead>
          <tr>
            <th width="35%">{{ title }}</th>
            <th v-for="label in labels" :key="label"> {{ label }}</th>
          </tr>
        </thead>
        <tbody>

          <template v-for="action in actions">
            <v-guard 
              v-if="action.key === 'Förmedlad externt'" 
              :permissions="['ticket-view-formedla_externt']" 
              :key="'guard-' + action.key"
            >
              <tr :key="action.key">
                <td :class="!action.action_child ? 'level-1' : 'level-0'">{{ action.key }}</td>
                <td v-for="year in years(action)" :key="year.key">
                  {{ year.doc_count == 0 ? '' : year.doc_count.toLocaleString('sv-SE') }}
                </td>
              </tr>
            </v-guard>
            <tr v-else :key="action.key">
              <td :class="!action.action_child ? 'level-1' : 'level-0'">{{ action.key }}</td>
              <td v-for="year in years(action)" :key="year.key">
                {{ year.doc_count == 0 ? '' : year.doc_count.toLocaleString('sv-SE') }}
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
  </div>


</template>
<script>

import moment from 'moment';

export default {
  data() {
    return {
      moment: moment,
      labelsCopy: [],
    }
  },
  props: {
    data: Array,
    title: String,
    yearsSelected: Array,
    hideParent: Array,
  },
  watch: {
    labels: function (val) {
      this.labelsCopy = val;
    },
  },
  computed: {
    labels() {
      let labels = [];

      this.data.forEach((value) => {
        if(Object.prototype.hasOwnProperty.call(value, 'action_child')) {
          value.action_child.buckets.forEach((bucket) => {
            if(Object.prototype.hasOwnProperty.call(bucket, 'nested_actions')) {
                bucket.nested_actions.nested_actions_years.year.buckets.forEach((bucket) => {
                  if (!labels.includes(bucket.key)) {
                      if ((this.yearsSelected.length && this.yearsSelected.includes(bucket.key.toString())) ||  (bucket.doc_count > 0 && bucket.nested_action_time.action_time_year.value > 0)) {
                          labels.push(bucket.key);
                      }
                  }
                })
              }
            })
          }
        })
      return labels.sort();
    },
    actions() {
      const actions = this.data.map((actionParent) => {
        if(this.hideParent.includes(actionParent.key)) {
          return [];
        }
        let actionChilds = actionParent.action_child.buckets.map(second => {
            return second
        })
        return [actionParent, ...actionChilds]
      });

      return actions.flat();
    }
  },
  mounted() {
    this.labelsCopy = this.labels;
  },
  methods: {

    capitalize(word) {
      return word.trim().replace(/^\w/, (c) => c.toUpperCase())
    },
    years(agg) {
      let years = [];
      if (Object.prototype.hasOwnProperty.call(agg, 'nested_actions')) {
        agg.nested_actions.nested_actions_years.year.buckets.forEach((bucket) => {
            if(this.labelsCopy.includes(bucket.key)) {
              years.push(bucket);
            } 
        });
 
        return years;
      }
    }
  }
}
</script>

<style scoped>
.level-0 {
  font-weight: 500;
}
.level-1 {
  padding-left: 45px !important;
}
</style>
